@import "./variables.scss";

#CustomStyle {
  .main-title {
    //background-color: rgb(18, 26, 32, 0.5);
    color: $light-blue;
    margin-top: 25%;
    font-size: 2.5em;
    font-weight: $fontWeightBold;
  }

  .section-title {
    //background-color: rgb(18, 26, 32, 0.5);
    color: $light-blue;
    font-size: 2.5em;
    font-weight: $fontWeightBold;
  }

  .sub-title {
    //background-color: rgb(18, 26, 32, 0.5);
    color: $light-blue;
    font-size: 1em;
    font-weight: $fontWeightBold;
  }

  .sub-paragraph {
    //background-color: rgb(18, 26, 32, 0.5);
    color: $light-blue;
    font-size: 1em;
    font-weight: $fontWeightBold;
    text-align: start;
  }

  .card-paragraph {
    font-size: 1em;
    font-weight: $fontWeightBold;
    text-align: center;
    color: $primaryColor;
  }

  //Footer
  .footer {
    background-image: url("../img/footer.png");
    width: 100%;
    height: 400px;
    background-size: 100% 100%;
  }

  .bottom {
    padding-top: 20%;
    bottom: 0;
    align-self: flex-end;
  }

  @media screen and (max-width: 960px) {
    .footer {
      background-size: cover;
    }
  }

  h1 {
    margin-top: auto;
    font-size: $fontSizeH1;
    font-weight: $fontWeightBold;
  }

  h2 {
    margin-top: auto;
    font-size: $fontSizeH2;
    font-weight: $fontWeightNormal;
  }

  h3 {
    margin-top: auto;
    font-size: $fontSizeH3;
    font-weight: $fontWeightNormal;
  }

  h4 {
    margin-top: auto;
    font-size: $fontSizeH4;
    font-weight: $fontWeightNormal;
  }

  h5 {
    margin-top: auto;
    font-size: $fontSizeH5;
    font-weight: $fontWeightNormal;
  }

  p {
    font-size: $fontSizeP;
  }

  .bold {
    font-weight: $fontWeightBold;
  }

  //Component
  .bordered-component {
    .p-component,
    .p-component * {
      border-radius: $cardBorderRadius;
    }
  }

  .full-width-component {
    width: 100vw;
  }

  //Input
  .p-inputtext {
    color: $inputTextColor;
    border: $inputBorder;
    border-radius: $borderRadius;
    width: $inputWidth;
  }

  .p-inputtext:enabled:hover {
    border-color: $inputBorderColorHover;
  }

  .p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0;
    border-color: $primaryColor;
  }

  //CheckBox
  .p-checkbox .p-checkbox-box {
    border-radius: $borderRadius;
  }

  .p-checkbox .p-checkbox-box:hover {
    border-color: $checkboxActiveBorderColor;
  }

  .p-checkbox .p-checkbox-box:focus {
    -webkit-box-shadow: 0 0 0 0;
    box-shadow: 0 0 0 0;
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: $checkboxActiveBg;
    background: $checkboxActiveBg;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: $checkboxActiveBg;
    background: $primaryColorBlur;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    // outline: 0 none;
    // outline-offset: 0;
    -webkit-box-shadow: $checkboxShadow;
    box-shadow: $checkboxShadow;
    border-color: $checkboxActiveBg;
  }

  //Card
  .bordered-card {
    .p-component,
    .p-component * {
      border-radius: $cardBorderRadius;
    }
  }

  .card {
    border-radius: $borderRadius;
    box-shadow: $cardShadow;
  }

  .p-card .p-card-body {
    background-color: $secondaryColor;
    border-radius: $cardBorderRadius;
  }

  //Panel
  .p-panel {
    .p-panel-header {
      background-color: $primaryColor;
    }
  }

  //Containers
  .custom-container {
    height: 100%;
    min-height: 100vh;
  }

  .full-height {
    height: 100%;
    align-content: center;
  }

  //Dropdown
  .p-dropdown {
    width: $fieldWidth;
    border-radius: $borderRadius;

    .p-inputtext {
      border: none;
    }
  }

  .p-dropdown:hover {
    border-color: $primaryColor;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: $dropdownBoxShadow;
    box-shadow: $dropdownBoxShadow;
    border-color: $dropdownActiveBg;
  }

  .p-dropdown:not(.p-disabled).p-disabled {
    border-color: $dropdownActiveBg;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: $primaryColorBlur;
  }

  //Password
  .p-field {
    position: relative;

    .p-password-panel {
      top: $inputHeight !important;
      left: calc((100% - #{$fieldWidth}) / 2) !important;
      width: $inputWidth;
      border-bottom-left-radius: $borderRadiusSoft;
      border-bottom-right-radius: $borderRadiusSoft;
    }
  }

  //Card
  .card-title {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .m-card {
    margin: $cardMargin;
  }

  //Timeline
  .p-timeline .p-timeline-event-marker {
    border-color: $primaryColor;
  }

  //Progress Bar
  .p-progressbar .p-progressbar-value {
    background: $primaryColor;
  }

  //Steps
  .p-steps .p-steps-item.p-highlight .p-steps-number {
    background: $primaryColor;
  }

  //Home -> Benchmark Section
  .benchmark {
    .p-progressbar {
      background: $progressbarBg;
    }
  }

  //Badge
  .badge-primary {
    background: $primaryColor !important;
  }

  .badge-secondary {
    background: $secondaryColor !important;
  }

  .badge-third {
    background: $thirdColor !important;
  }

  //Img
  .img-circled {
    border-radius: 500px;
    height: 150px;
    background-color: $secondaryColor;
  }

  //Profile
  .profile {
    color: $secondaryColor;
  }

  .img-size {
    width: 100%;
  }

  //Tabs
  .p-tabview .p-tabview-nav {
    justify-content: center;
    background: $primaryColor;
  }

  .p-tabview .p-tabview-panels {
    background: $primaryColor;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: $primaryColor;
    border-color: $secondaryColor;
    color: $secondaryColor;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: $primaryColor;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link:enabled:hover {
    border-color: $inputBorderColorHover;
  }
}
