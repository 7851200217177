@import "../../../assets/theme/variables.scss";

//Profile
.profile {
  color: $secondaryColor;

  h2 {
    font-size: 0.7em;
  }

  .address {
    font-size: 0.7em;
  }
}
