@import "./variables.scss";

.GeneralStyle {
    //General Typography
    font-family: $fontFamily;
    font-size: $fontSize;

    .strong {
        font-weight: $strongText;
    }

    .p-full-width {
        width: 100% !important;
    }

    //Button
    .p-small {
        .p-button {
            font-size: $fontSizeSmall;
            min-width: 0px;
            width: auto;
        }
    }

    .p-button {
        background-color: $primaryColor;
        border: $buttonBorder;
        color: $buttonTextColor;
        border-radius: $borderRadius;
        min-width: 100px;
        width: auto; //$buttonWidth;
        text-align: center;
        align-items: center;

        &.secondary {
            background-color: $secondaryColor;
        }

        &.third {
            background-color: $thirdColor;
            color: $primaryColor;
        }
    }

    .p-button:enabled:hover {
        background-color: $primaryHover;
    }

    .p-button:focus {
        -webkit-box-shadow: 0 0 0 0;
        box-shadow: 0 0 0 0;
    }

    .p-button:disabled {
        background-color: $primaryColorBlur;
    }

    .p-button-label {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }

    .p-button.p-button-outlined {
        color: $primaryColor;
    }

    .p-button.p-button-outlined:enabled:hover {
        background: rgba(33, 150, 243, 0.04);
        color: $primaryColor;
    }




    //Label
    label {
        margin-bottom: 0px;
        margin-left: 5px;
    }

    //Links
    a {
        color: $linkColor;
    }

    //Backgrounds
    .background-opacity {
        background-color: rgb(18, 26, 32, 0.2);
    }

    .background-primary {
        background-color: $primaryColor;
    }

    .background-secondary {
        background-color: $secondaryColor;
    }

    .background-dark {
        background-color: $dark;
    }

    .background-yellow {
        background-color: $yellow;
    }

    .background-grey {
        background-color: $grey;
    }

    .background-white {
        background-color: $white;
    }

    .background-img {
        background-image: url(../img/homepage.png);
        background-repeat: no-repeat;
        background-size: cover;
    }

    //Header
    .header {
        background-color: $blue-dark;
        border: none;
        border-radius: 0;
        z-index: 1000;
    }

    .p-menubar {
        background: $primaryColor;
        color: #495057;
        border: 1px solid $primaryColor;
        border-radius: 0px;
    }

    .p-menubar-root-list {
        position: absolute;
        right: 0;
    }

    .p-menubar-button {
        position: absolute;
        right: 0;
    }

    //Margin & Padding
    .mb-field {
        margin-bottom: 2%;
    }

    .line {
        width: 50%;
        margin: auto;
        border-bottom: $inputBorder;
    }

    .line-full {
        width: 100%;
        margin: auto;
        border-bottom: $inputBorder;
    }

    //Bordered Box
    .bordered-box {
        border: 1px solid $dark;
    }

    //Features
    .features {

        color: $secondaryColor;
        text-align: center;

        .img-size {
            width: 50% !important;
        }

        .p-card .p-card-body {
            background-color: $primaryColor !important;
            border-radius: 20px;
        }
    }

    //Modal
    .p-dialog-enter-done {
        width: 80%;
    }

    //Button
    .btn-primary {
        background-color: $primaryColor;
    }

    .btn-secondary {
        background-color: $secondaryColor;
    }

    .btn-third {
        background-color: $thirdColor;
        color: $primaryColor;
    }

    //Profile
    .profile-item {
        display: flex;
        align-content: center;

        p {
            margin-bottom: 0;
        }
    }

    //Tabs
    .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .header {

        .card {
            border: none;
            border-radius: 0px;
        }

        .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover {
            background: $primaryColor;
            color: $secondaryColor;
            text-decoration: underline;
            scale: 1.5 !important;
        }

        .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link {
            color: $secondaryColor;
        }

        .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
            color: $secondaryColor;
        }

        .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:focus {
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        @media screen and (max-width: 960px) {

            .p-menubar .p-menubar-button {
                margin-right: 2%;
            }

            .p-menubar .p-menubar-button:focus {
                -webkit-box-shadow: none;
                box-shadow: none;
            }

            .p-menubar .p-menubar-button:hover {
                margin-right: 2%;
                color: $secondaryColor;
                background: $primaryColor;
            }

            .p-menubar .p-menubar-root-list {
                color: $secondaryColor;
                background: $primaryColor;
            }

            .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text {
                color: $secondaryColor;
            }
        }

    }

}